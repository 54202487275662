* {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif !important;
}

html,
#root {
	color: #0a1020;
	background: #f6f6f6;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px rgba(17, 61, 97, 0) inset;
	transition: background-color 5000s ease-in-out 0s;
}
